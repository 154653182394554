import ModalConstants from '../constants/modal.constants';

const initialState = {
  accountModalVisible: false,
  creatorModalVisible: false,
  moonpayModalVisible: false,
  moonpayAmount: '0',
  photoCarouselModalVisible: false,
  csvReportModalVisible: false,
};

export function Modal(state = initialState, action) {
  switch (action.type) {
    case ModalConstants.SHOW_ACCOUNT_MODAL:
      return {
        ...state,
        accountModalVisible: true,
      };
    case ModalConstants.HIDE_ACCOUNT_MODAL:
      return {
        ...state,
        accountModalVisible: false,
      };
    case ModalConstants.SHOW_CREATOR_MODAL:
      return {
        ...state,
        creatorModalVisible: true,
      };
    case ModalConstants.HIDE_CREATOR_MODAL:
      return {
        ...state,
        creatorModalVisible: false,
      };
    case ModalConstants.SHOW_PHOTO_CAROUSEL_MODAL:
      return {
        ...state,
        photoCarouselModalVisible: true,
      };
    case ModalConstants.HIDE_PHOTO_CAROUSEL_MODAL:
      return {
        ...state,
        photoCarouselModalVisible: false,
      };
    case ModalConstants.SHOW_CSV_REPORT:
      return {
        ...state,
        csvReportModalVisible: true,
      };
    case ModalConstants.HIDE_CSV_REPORT:
      return {
        ...state,
        csvReportModalVisible: false,
      };
    case ModalConstants.SHOW_MOONPAY_MODAL:
      return {
        ...state,
        moonpayModalVisible: true,
        moonpayAmount: action.payload,
      };
    case ModalConstants.HIDE_MOONPAY_MODAL:
      return {
        ...state,
        moonpayModalVisible: false,
        moonpayAmount: 0,
      };
    case ModalConstants.UPDATE_MOONPAY_AMOUNT:
      return {
        ...state,
        moonpayAmount: action.payload,
      };

    default: {
      return state;
    }
  }
}
