import ModalConstants from '../constants/modal.constants';

const ModalActions = {
  showAccountModal,
  hideAccountModal,
  showPhotoCarouselModal,
  hidePhotoCarouselModal,
  showCsvReportModal,
  hideCsvReportModal,
  showMoonpayModal,
  hideMoonpayModal,
  updateMoonpayAmount,
  showCreatorModal,
  hideCreatorModal,
};

function showAccountModal() {
  return dispatch => {
    dispatch(_showAccountModal());
  };
}

const _showAccountModal = () => {
  return {
    type: ModalConstants.SHOW_ACCOUNT_MODAL,
  };
};

function hideAccountModal() {
  return dispatch => {
    dispatch(_hideAccountModal());
  };
}

const _hideAccountModal = () => {
  return {
    type: ModalConstants.HIDE_ACCOUNT_MODAL,
  };
};

function showCreatorModal() {
  return dispatch => {
    dispatch(_showCreatorModal());
  };
}

const _showCreatorModal = () => {
  return {
    type: ModalConstants.SHOW_CREATOR_MODAL,
  };
};

function hideCreatorModal() {
  return dispatch => {
    dispatch(_hideCreatorModal());
  };
}

const _hideCreatorModal = () => {
  return {
    type: ModalConstants.HIDE_CREATOR_MODAL,
  };
};

function showPhotoCarouselModal() {
  return dispatch => {
    dispatch(_showPhotoCarouselModal());
  };
}

const _showPhotoCarouselModal = () => {
  return {
    type: ModalConstants.SHOW_PHOTO_CAROUSEL_MODAL,
  };
};

function hidePhotoCarouselModal() {
  return dispatch => {
    dispatch(_hidePhotoCarouselModal());
  };
}

const _hidePhotoCarouselModal = () => {
  return {
    type: ModalConstants.HIDE_PHOTO_CAROUSEL_MODAL,
  };
};

function showCsvReportModal() {
  return dispatch => {
    dispatch(_showCsvReportModal());
  };
}

const _showCsvReportModal = () => {
  return {
    type: ModalConstants.SHOW_CSV_REPORT,
  };
};

function hideCsvReportModal() {
  return dispatch => {
    dispatch(_hideCsvReportModal());
  };
}

const _hideCsvReportModal = () => {
  return {
    type: ModalConstants.HIDE_CSV_REPORT,
  };
};

function showMoonpayModal(payload) {
  return dispatch => {
    dispatch(_showMoonpayModal(payload));
  };
}

const _showMoonpayModal = payload => {
  return {
    type: ModalConstants.SHOW_MOONPAY_MODAL,
    payload: payload,
  };
};

function hideMoonpayModal() {
  return dispatch => {
    dispatch(_hideMoonpayModal());
  };
}

const _hideMoonpayModal = () => {
  return {
    type: ModalConstants.HIDE_MOONPAY_MODAL,
  };
};

function updateMoonpayAmount(payload) {
  return dispatch => {
    dispatch(_updateMoonpayAmount(payload));
  };
}

const _updateMoonpayAmount = payload => {
  return {
    type: ModalConstants.HIDE_MOONPAY_MODAL,
    payload: payload,
  };
};

export default ModalActions;
