import React, { useState } from 'react';
import Modal from '../Modal';
import styles from './styles.module.scss';
import { StyledButton, StyledCheckbox } from 'components/StyledComponents';
import { MenuItem, Select, useMediaQuery } from '@mui/material';
import Input from 'components/Input';
import { FormControlLabel } from '@mui/material';
import Close from '@mui/icons-material/Close';
import cx from 'classnames';
import { useApi } from 'api';
import { CREATOR_STATUSES } from 'constants/user.constants';
import toast from 'utils/toast';
import { Link, useHistory } from 'react-router-dom';

const SocialMedia = [
  {
    id: '1',
    name: 'youtube',
  },
  {
    id: '2',
    name: 'instagram',
  },
  {
    id: '3',
    name: 'x',
  },
];
const SocialMediaInput = ({
  platformName,
  url,
  subscribers,
  handleInputChange,
  handleRemove,
  index,
}) => (
  <div className={styles.socialMediaInput}>
    <div className={styles.formGroup}>
      <div className={styles.formLabel}>Platform</div>
      <Select
        name="platform"
        value={platformName}
        onChange={e => {
          handleInputChange(e, index);
        }}
        className={styles.select}
        defaultValue={''}
        sx={{
          '.MuiSelect-select': {
            padding: '0 !important',
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--primary-color) !important',
            },
          },
        }}
      >
        <MenuItem value={''} disabled>
          <p className={styles.menuLabel}>Choose platform</p>
        </MenuItem>
        {SocialMedia.map(col => (
          <MenuItem
            key={col?.name}
            value={col?.name}
            sx={{
              '&:hover': {
                backgroundColor: 'var(--secondary-background-color)',
              },
              '&.Mui-selected': {
                backgroundColor: 'var(--secondary-background-color)',
                '&:hover': {
                  backgroundColor: 'var(--secondary-background-color)',
                },
              },
            }}
          >
            {col.name}
          </MenuItem>
        ))}
      </Select>
    </div>

    <Input
      name="url"
      placeholder=""
      value={url}
      onChange={e => {
        handleInputChange(e, index);
      }}
      onKeyDown={e => {
        handleInputChange(e, index);
      }}
      label="URL"
      classNameCont={styles.inputURL}
      type="text"
    />
    <Input
      name="subscribers"
      placeholder=""
      value={subscribers}
      onChange={e => {
        handleInputChange(e, index);
      }}
      onKeyDown={e => {
        handleInputChange(e, index);
      }}
      label="Subscribers"
      type="number"
    />
    <div
      className={cx(styles.closeBtn, index === 0 && styles.hideCloseBtn)}
      onClick={() => {
        if (index > 0) handleRemove(index);
      }}
    >
      <Close />
    </div>
  </div>
);
const CreatorModal = ({
  visible,
  onClose,
  authToken,
  creatorStatus,
  updateUser,
}) => {
  console.log('creatorStatus', creatorStatus);
  const [socialMedia, setSocialMedia] = useState([
    { platform: '', url: '', subscribers: 0 },
  ]);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const { updateSocialMedia } = useApi();
  const [selfState, setSelfState] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const handleDisconnect = () => {
    setSocialMedia([{ platform: '', url: '', subscribers: 0 }]);
    onClose();
  };

  const handlePlatformChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...socialMedia];
    list[index][name] = name === 'subscribers' ? parseFloat(value) : value;
    setSocialMedia(list);
  };
  const handlePlatformRemove = index => {
    const list = [...socialMedia];
    list.splice(index, 1);
    setSocialMedia(list);
  };

  const handlePlatformAdd = () => {
    setSocialMedia([...socialMedia, { platform: '', url: '', subscribers: 0 }]);
  };

  const handleSubmit = async () => {
    if (!selfState) {
      return;
    }
    setSubmitting(true);
    const tempSocialList = socialMedia.filter(
      item => item.platform && item.url && item.subscribers
    );
    if (tempSocialList.length > 0) {
      try {
        const { data } = await updateSocialMedia(tempSocialList, authToken);
        if (data?.message === 'OK') {
          updateUser(tempSocialList, data.creatorStatusId);
          onClose();
          if (data.creatorStatusId === CREATOR_STATUSES.APPROVED) {
            history.push('/create');
            toast('success', 'Your applicant was approved');
          } else {
            toast('success', 'Your request was sent');
          }
        } else {
          toast('error', 'Your request is not successful, please try again');
        }
      } catch (error) {
        console.log(error);
        toast('error', 'Your request is not successful, please try again');
      }
    } else {
      toast('error', 'Please fill all required information');
    }
    setSubmitting(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        onClose={handleDisconnect}
        submitDisabled={true}
        showTitle={false}
        width={'800px'}
        showCloseIcon={false}
      >
        {creatorStatus === CREATOR_STATUSES.SUBMITTED ? (
          <>
            <div
              className={styles.title}
            >{`We are processing your applicant`}</div>
          </>
        ) : creatorStatus === CREATOR_STATUSES.REJECTED ? (
          <>
            <div
              className={styles.title}
            >{`Your applicant was rejected. Please contact admin@minti.fun`}</div>
          </>
        ) : creatorStatus === CREATOR_STATUSES.PAUSED ? (
          <>
            <div
              className={styles.title}
            >{`Your applicant was paused. Please contact admin@minti.fun`}</div>
          </>
        ) : (
          <>
            <div className={styles.title}>{`Content Creator Consent Form`}</div>
            <div className={styles.subtitleContainer}>
              <div className={styles.subtitle}>
                Minti Content Creators are curated for quality and virality.
                Tell us about your presence and success on other platforms.
              </div>
            </div>
            <div className={styles.content}>
              {!isMobile ? (
                socialMedia.map((item, index) => (
                  <SocialMediaInput
                    key={index}
                    index={index}
                    platformName={item.platform}
                    url={item.url}
                    subscribers={item.subscribers}
                    handleInputChange={handlePlatformChange}
                    handleRemove={index => handlePlatformRemove(index)}
                  />
                ))
              ) : (
                <SocialMediaInput
                  index={0}
                  platformName={socialMedia[0].name}
                  url={socialMedia[0].url}
                  subscribers={socialMedia[0].subscribers}
                  handleInputChange={handlePlatformChange}
                  handleRemove={index => handlePlatformRemove(index)}
                />
              )}
              {!isMobile && (
                <div className={styles.addBtn}>
                  <StyledButton
                    disabled={socialMedia.length > 2}
                    onClick={() => handlePlatformAdd()}
                  >
                    Add
                  </StyledButton>
                </div>
              )}
            </div>
            <FormControlLabel
              className={styles.checkBox}
              label={
                <p>
                  I confirm that all the information I have provided is true and
                  accurate. I agree to Minti&apos;s{' '}
                  <Link to="/acceptable-use-policy">
                    {' '}
                    Acceptable Use Policy{' '}
                  </Link>
                  , <Link to="/privacy-policy"> Privacy Policy</Link> &{' '}
                  <Link to="/terms-of-service">Terms of Service </Link>. (Check
                  all Minti’s <Link to="/policies">policies</Link> ). I
                  understand that providing false information may result in the
                  suspension or termination of my account, including the
                  forfeiture of any unredeemed tokens.
                </p>
              }
              control={
                <StyledCheckbox
                  checked={selfState}
                  onChange={() => setSelfState(prev => !prev)}
                />
              }
            />
            <div className={styles.btnGroup}>
              <StyledButton cancel="true" onClick={() => handleDisconnect()}>
                Cancel
              </StyledButton>
              <StyledButton
                disabled={!selfState || submitting}
                onClick={() => handleSubmit()}
              >
                Enter
              </StyledButton>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default React.memo(CreatorModal, (prevProps, nextProps) => {
  return prevProps.visible === nextProps.visible;
});
