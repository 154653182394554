import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { ClipLoader } from 'react-spinners';
// import Skeleton from 'react-loading-skeleton';

// import { formatNumber } from 'utils';
import PriceInput from 'components/PriceInput';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import InputError from '../InputError';
import { useApi } from 'api';
import showToast from 'utils/toast';
// import StripePaymentForm from './stripe.payment';
// import { Elements } from '@stripe/react-stripe-js';
// import { stripePromise } from 'components/app';
import PayPalPaymentModal from 'components/PayPalPaymentModal';

const OfferModal = ({
  visible,
  onClose,
  onMakeOffer,
  confirming,
  contractApproving,
  token,
  paymentMethod,
  authToken,
  nftId,
}) => {
  const [price, setPrice] = useState('');
  const [selected, setSelected] = useState([]);
  const [inputError, setInputError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { createPayPalAuthorizedLink } = useApi();

  const getEndTime = () => {
    const days = paymentMethod === 'fiat' ? 29 : 1;
    return new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
  };

  const [endTime, setEndTime] = useState(getEndTime());
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (visible) {
      setLoading(false);
      setDisplayPaymentModal(false);
      setClientSecret(null);
      setPrice('');
      setEndTime(getEndTime());
      if (paymentMethod === 'crypto') {
        setSelected([token]);
      }
    }
  }, [visible, paymentMethod]);

  const handleMakeOffer = async () => {
    if (confirming || loading) {
      return;
    }

    if (paymentMethod === 'fiat') {
      setLoading(true);
      const { data, errors } = await createPayPalAuthorizedLink(
        authToken,
        nftId,
        Number(price),
        Math.floor(endTime.getTime() / 1000) // deadline
      );

      if (!errors) {
        setClientSecret(data);
        setDisplayPaymentModal(true);
      } else {
        showToast(
          'error',
          errors[0]?.message || 'Something went wrong. Please try again later.'
        );
        setLoading(false);
      }
    } else {
      let quant = 1;

      onMakeOffer(
        paymentMethod === 'crypto' ? selected[0] : undefined,
        price,
        quant,
        endTime
      );
    }
  };

  const handleAuthorizeModalClose = loading => {
    setDisplayPaymentModal(false);
    setClientSecret(null);
    setLoading(loading);
    if (loading) {
      onClose(true);
    }
  };

  const validateInput = () => {
    if (price.length === 0 || parseFloat(price) == 0) return false;
    if (endTime.getTime() < new Date().getTime()) return false;
    return true;
  };

  return (
    <Modal
      visible={visible}
      title="Place your offer"
      onClose={!loading ? onClose : null}
      submitDisabled={
        contractApproving ||
        confirming ||
        loading ||
        inputError ||
        !validateInput()
      }
      submitLabel={
        displayPaymentModal ? (
          ''
        ) : confirming || loading ? (
          <ClipLoader color="#FFF" size={16} />
        ) : (
          'Place Offer'
        )
      }
      onSubmit={() => (validateInput() ? handleMakeOffer() : null)}
      showCloseIcon={false}
    >
      {!displayPaymentModal ? (
        <>
          <div className={styles.formGroup}>
            <div className={styles.formLabel}>Price</div>
            <div className={styles.formInputCont}>
              {/* <div className={styles.select}>
            <div className={styles.selectedToken}>
              <img src={selected[0]?.icon} className={styles.tokenIcon} />
              <div className={styles.tokenSymbol}>{selected[0]?.symbol}</div>
            </div>
          </div> */}
              <PriceInput
                className={styles.formInput}
                placeholder="0.00"
                decimals={selected[0]?.decimals || 0}
                value={'' + price}
                onChange={setPrice}
                disabled={confirming}
                onInputError={err => setInputError(err)}
              />
              {/* <div className={styles.usdPrice}>
            {!isNaN(selected[0]?.price) && selected[0]?.price !== null ? (
              `$${formatNumber(
                ((parseFloat(price) || 0) * selected[0]?.price).toFixed(2)
              )}`
            ) : (
              <Skeleton width={100} height={24} />
            )}
          </div> */}
            </div>
            <InputError text={inputError} />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formLabel}>Offer Expiration</div>
            <div className={styles.formInputCont}>
              <Datetime
                value={endTime}
                onChange={val => setEndTime(val.toDate())}
                inputProps={{
                  className: styles.formInput,
                  onKeyDown: e => e.preventDefault(),
                  disabled: confirming || paymentMethod === 'fiat',
                }}
                closeOnSelect
                isValidDate={cur =>
                  cur.valueOf() > new Date().getTime() - 1000 * 60 * 60 * 24
                }
              />
            </div>
          </div>
        </>
      ) : (
        // <Elements stripe={stripePromise}>
        //   <StripePaymentForm clientSecret={clientSecret} onSuccess={onClose} />
        // </Elements>

        <PayPalPaymentModal
          visible={Boolean(clientSecret)}
          onClose={handleAuthorizeModalClose}
          orderId={clientSecret}
          type="AUTHORIZE"
          authToken={authToken}
        />
      )}
    </Modal>
  );
};

export default OfferModal;
