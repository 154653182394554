import React from 'react';
import styles from './../styles.module.scss';
import LicenseGrid from '../ExternalDocumentsGrid';

import Header from 'components/header';
import FooterAction from '../../../components/FooterAction';
import Footer from '../../../components/Footer';

const Policy = ({ lastUpdated, title }) => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated={lastUpdated}
          title={title}
          url={`/files/${title}.pdf`}
        />
      </div>
      <Footer />

      <FooterAction />
    </div>
  );
};
export default Policy;
