import React, { useRef, useEffect } from 'react';
import cx from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles.module.scss';
import { StyledButton } from 'components/StyledComponents';

const Modal = ({
  visible,
  title,
  onClose,
  children,
  submitDisabled,
  submitLabel,
  isSubmitDeny = false,
  onSubmit,
  small,
  showCloseIcon = true,
  defaultPadding = true,
  higherLayer = false,
  loading = false,
  autoWidth = false,
  width = undefined,
}) => {
  const confirmButton = useRef(null);
  useEffect(() => {
    if (confirmButton && visible) {
      const listener = event => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
          confirmButton.current.click();
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  }, [visible, confirmButton]);
  return (
    <div
      className={cx(
        styles.container,
        visible ? styles.visible : null,
        higherLayer && styles.higherLayer
      )}
    >
      <div className={styles.backdrop} onClick={onClose}></div>
      <div
        className={cx(
          styles.modal,
          small && styles.small,
          loading && styles.buySkeleton,
          autoWidth && styles.autoWidth
        )}
        style={{
          ...(width && { width }),
        }}
      >
        {(title || showCloseIcon) && (
          <div
            className={
              title
                ? styles.header
                : cx(styles.header, styles.headerWithoutTitle)
            }
          >
            {title ? <div className={styles.title}>{title}</div> : null}
            {showCloseIcon ? (
              <div className={styles.closeButton} onClick={onClose}>
                <CloseIcon />
              </div>
            ) : null}
          </div>
        )}
        <div className={defaultPadding ? styles.body : ''}>{children}</div>
        {submitLabel && (
          <div className={styles.footer}>
            <StyledButton
              className={
                isSubmitDeny
                  ? cx(
                      styles.submitButton,
                      submitDisabled && styles.disabled,
                      styles.submitDenyButton
                    )
                  : cx(styles.submitButton, submitDisabled && styles.disabled)
              }
              onClick={onSubmit}
              ref={confirmButton}
              disabled={submitDisabled}
            >
              {submitLabel}
            </StyledButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
