export const NFT_STATUSES = {
  PREMINT: 'PreMint',
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED_BY_ADMIN: 'Approved by Admin',
  DENIED_BY_ADMIN: 'Denied by Admin',
  ERROR_MINTING_TX: 'Processing Error',
  MINTED: 'Minted',
  BURNED: 'Burned',
  PENDING_MINTING: 'Processing...',
  PAYING_FEE_PENDING: 'Paying Fee Pending',
  APPROVE_MINTING_PENDING: 'Approve Minting Pending',
  PENDING_WHITELIST: 'Approval Process in Progress, please wait...',
  APPROVE_PENDING: 'Approve Pending, please wait...',
  DRAFT: 'Draft',
};

export const NFT_STATUSES_CODES = {
  PREMINT: 1,
  PAYING_FEE_PENDING: 2,
  PENDING_APPROVAL: 3,
  APPROVED_BY_ADMIN: 4,
  DENIED_BY_ADMIN: 5,
  ERROR_MINTING_TX: 6,
  PENDING_MINTING: 7,
  MINTED: 8,
  BURNED: 9,
  TRANSAK_PROCESSING: 10,
  FIAT_PROCESSING: 11,
  PENDING_TRANSFERRING: 12,
  LISTING: 13,
};
