import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import styles from './styles.module.scss';
import { StyledButton } from 'components/StyledComponents';
import { useApi } from 'api';
import toast from 'utils/toast';
// import AutocompleteStripeCountry from 'components/AutocompleteStripeCountry';
import { Checkbox, FormControlLabel, styled, Typography } from '@mui/material';

const CustomCheckbox = styled(Checkbox)(() => ({
  color: 'lightgray',
  '&.Mui-checked': {
    color: 'var(--primary-color)',
  },
  '&.MuiCheckbox-indeterminate': {
    color: 'var(--primary-color)',
  },
}));

function BecomeSellerModal({ visible, onClose, authToken }) {
  const [loading, setLoading] = useState(false);
  const { registerPayPalSeller } = useApi();
  const [selfState, setSelfState] = useState(false);

  const handleRegisterSellerAccount = async () => {
    try {
      setLoading(true);
      const res = await registerPayPalSeller(authToken);

      // Redirect to registration page
      window.location.href = res.data;
    } catch (error) {
      toast('error', 'Something went wrong. Please try again later.');
    }
  };

  useEffect(() => {
    // Reset
    setLoading(false);
    setSelfState(false);
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose} showCloseIcon={false}>
      <div className={styles.title}>Set up payment account</div>
      <div className={styles.description}>
        To activate your payment account, please create a PayPal account by
        clicking the button below.
      </div>
      <div className={styles.btnContainer}>
        {/* <div className={styles.btnGroup}>
          <AutocompleteStripeCountry
            countries={countries}
            value={selectedCountry}
            onSelect={handleAutocompleteSelection}
            displayText="Select Your Country"
          />
        </div> */}
        <FormControlLabel
          onClick={e => e.stopPropagation()}
          label={
            <Typography className={styles.mainFormTitle}>
              I confirm that this is a one-time only action
            </Typography>
          }
          control={
            <CustomCheckbox
              checked={selfState}
              onChange={() => setSelfState(prev => !prev)}
            />
          }
        />
        <div className={styles.btnGroup}>
          <StyledButton cancel="true" onClick={() => onClose()}>
            Cancel
          </StyledButton>
          <StyledButton
            onClick={() => handleRegisterSellerAccount()}
            disabled={loading || !selfState}
          >
            Create Account
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
}

export default BecomeSellerModal;
