export const businessPolicies = [
  { id: 1, name: 'Acceptable Use Policy', url: 'acceptable-use-policy' },
  { id: 2, name: 'Customer Support', url: 'customer-support' },
  { id: 3, name: 'Privacy Policy', url: 'privacy-policy' },
  {
    id: 4,
    name: 'Prohibited Conduct Policy',
    url: 'prohibited-conduct-policy',
  },
  {
    id: 5,
    name: 'Seller Onboarding and Moderation Policies',
    url: 'seller-onboarding-and-moderation-policies',
  },
  { id: 6, name: 'Terms of Service', url: 'terms-of-service' },
  {
    id: 7,
    name: 'User Agreement; Sellers & Buyers',
    url: 'user-agreement-sellers-buyers',
  },
];

export const riskPolicies = [
  { id: 1, name: 'Anti-Fraud Policy', url: 'anti-fraud-policy' },
  { id: 2, name: 'Compliance Policy', url: 'compliance-policy' },
  {
    id: 3,
    name: 'Dispute Resolution & Chargeback Policy',
    url: 'dispute-resolution-chargeback-policy',
  },
  {
    id: 4,
    name: 'DMCA Copyright Infringement Policy',
    url: 'dmca-copyright-infringement-policy',
  },
  { id: 5, name: 'Incident Response Plan', url: 'incident-response-plan' },
  {
    id: 6,
    name: 'Intellectual Property Policy',
    url: 'intellectual-property-policy',
  },
  {
    id: 7,
    name: 'Seller Verification & KYC Process',
    url: 'seller-verification-kyc-process',
  },
];

export const paymentPolicies = [
  { id: 1, name: 'Refund Policy', url: 'refund-policy' },
  {
    id: 2,
    name: 'Security and Data Protection Measures',
    url: 'security-and-data-protection-measures',
  },
];
