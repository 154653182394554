import React, { useEffect, useRef } from 'react';
import { loadScript } from '@paypal/paypal-js';
import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import { useApi } from 'api';
import showToast from 'utils/toast';

const PayPalPaymentModal = ({ orderId, onClose, type = 'BUY', authToken }) => {
  const paypalRef = useRef(null);
  const paypalActionsRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const { validatePayPalCapture } = useApi();

  useEffect(() => {
    let isMounted = true;

    async function initializePayPal() {
      if (!paypalRef.current || !orderId) return;

      try {
        const paypal = await loadScript({
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          intent: type === 'BUY' ? 'capture' : 'authorize',
        });

        if (!isMounted) return;

        // Clear the div before rendering
        paypalRef.current.innerHTML = '';

        paypal
          .Buttons({
            style: { layout: 'vertical' },
            createOrder: async () => {
              return orderId;
            },
            onApprove: async (_, actions) => {
              paypalActionsRef.current.disable();
              setLoading(true);
              // Fetch the order details
              const orderDetails = await actions.order.get();
              const amount = orderDetails.purchase_units[0].amount.value;
              const orderId = orderDetails.id;

              const { data: validated } = await validatePayPalCapture(
                authToken,
                orderId,
                Number(amount)
              );

              if (!validated) {
                showToast(
                  'error',
                  'Cannot proceed with the payment. Please try again.'
                );
                paypalActionsRef.current.enable();
                setLoading(false);
                return;
              }

              if (type === 'BUY') {
                await actions.order.capture();
              } else {
                // Authorize the payment
                await actions.order.authorize();
              }

              onClose(true);
            },
            onError: err => {
              console.error('PayPal Checkout Error:', err);
              paypalActionsRef.current.enable();
              setLoading(false);
            },
            onCancel: () => {
              paypalActionsRef.current.enable();
              setLoading(false);
            },
            onInit: (_, actions) => {
              actions.enable();
              paypalActionsRef.current = actions;
            },
          })
          .render(paypalRef.current);
      } catch (error) {
        console.error('PayPal Checkout Error:', error);
      }
    }

    initializePayPal();

    return () => {
      isMounted = false;
    };
  }, [orderId, type]);

  return (
    <Modal
      visible={Boolean(orderId)}
      title={'Complete Payment'}
      onClose={() => {
        !loading ? onClose(false) : null;
      }}
      submitDisabled={false}
      onSubmit={() => {}}
      showCloseIcon
      loading={loading}
    >
      <div className={styles.paypalModal}>
        <div ref={paypalRef}></div>
      </div>
    </Modal>
  );
};

export default PayPalPaymentModal;
