/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, withRouter, Link } from 'react-router-dom';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import WalletConnectActions from 'actions/walletconnect.actions';
import HeaderOptions from 'actions/header.actions';
import AuthActions from 'actions/auth.actions';
import Identicon from 'components/Identicon';
import HomeIcon from '@mui/icons-material/Home';
import LightMode from '@mui/icons-material/LightMode';
import DarkMode from '@mui/icons-material/DarkMode';
import HomeIconOutlined from '@mui/icons-material/HomeOutlined';
import logo from 'assets/imgs/mintifi.png';
import IconPerson from 'assets/icons/iconPerson';
import IconSocialMedia from 'assets/icons/iconSocialMedia';
import IconSupport from 'assets/icons/iconSupport';
import IconPaper from 'assets/icons/iconPaper';
import IconLogout from 'assets/icons/iconLogout';
import IconWallet from 'assets/icons/iconWallet';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import IconDiscord from 'assets/icons/iconDiscord';
import {
  handleOpenPlatform,
  notificationBadgeContent,
  shortenAddress,
} from 'utils';
import { useAppKit, useAppKitAccount } from '@reown/appkit/react';

import SearchBox from 'components/SearchBox';
import { useApi } from 'api';
import { StyledBadge, StyledButton } from 'components/StyledComponents';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Menu,
  MenuItem,
  Drawer,
  Stack,
  IconButton,
  Divider,
  ListItemIcon,
  ListItemText,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from '@mui/material';
import { setRecentConnectionMeta } from 'connection/useRecentConnection';
import styles from './styles.module.scss';
import IconBell from '@mui/icons-material/Notifications';
// import MemoizedMobileNotifications from './components/MemoizedMobileNotifications';
import MemoizedNotificationItems from './components/MemoizedNotificationItems';
import NotiActions from 'actions/noti.actions';
import ModalActions from 'actions/modal.actions';
import { CREATOR_STATUSES } from 'constants/user.constants';
import MemoizedMobileNotifications from './components/MemoizedMobileNotifications';

const Header = ({ isSticky, hideMenu }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const { notificationViewed } = useApi();

  const { user, authToken } = useSelector(state => state.Auth);

  const { mode } = useSelector(state => state.HeaderOptions);

  const { total: totalNotifications } = useSelector(
    state => state.Notifications
  );
  const { open } = useAppKit();
  const { address: account } = useAppKitAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);

  const onNotificationRead = async notification => {
    if (!authToken || notification.status !== 'new') return;

    const { data } = await notificationViewed(authToken, {
      notificationId: notification.notificationId,
    });

    if (data?.message === 'OK') {
      // set viewed
      dispatch(NotiActions.notificationViewed(notification));
    }
  };

  const handleMenuClose = () => {
    setOpenDrawer(false);
    setAnchorEl(null);
    setAnchorNotificationsEl(null);
  };

  const handleConnectWallet = () => {
    handleMenuClose();
    open();
  };

  useEffect(() => {
    //Set your APP_ID
    var APP_ID = 'tr9h2wqy';

    // eslint-disable-next-line
    (function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + APP_ID;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
    window.Intercom('boot', {
      app_id: APP_ID,
    });
  }, []);

  useEffect(() => {
    handleMenuClose();
  }, [isMobile]);

  const handleSignIn = () => {
    window.scrollTo(0, 0);
    history.push('/auth/sign-in');
  };

  const handleSignOut = async () => {
    window.scrollTo(0, 0);
    setRecentConnectionMeta();
    dispatch(WalletConnectActions.disconnectWallet());
    dispatch(AuthActions.signOut());
    dispatch(NotiActions.resetNotifications());
    dispatch(NotiActions.resetCheckedAsset());
    handleMenuClose();
    window.localStorage.removeItem('nftItem');
  };

  const handleExploreNavigate = () => {
    history.push('/explore');
    handleMenuClose();
  };

  const handleProvileNavigate = () => {
    history.push('/profile');
    handleMenuClose();
  };

  const handleNotificationsMenuOpen = e => {
    setAnchorNotificationsEl(e.currentTarget);
  };

  const goToCreateNft = () => {
    if (!user.is2fa) {
      history.push('/profile/2fa');
      handleMenuClose();

      return;
    }

    if (
      !user.creatorStatusId ||
      [CREATOR_STATUSES.PAUSED, CREATOR_STATUSES.REJECTED].includes(
        user.creatorStatusId
      )
    ) {
      dispatch(ModalActions.showCreatorModal());
      history.push('/');
      handleMenuClose();

      return;
    }

    history.push(`/create`);
  };

  const goToAddToken = () => {
    if (!user.is2fa) {
      history.push('/profile/2fa');
    } else if (!user.address) {
      history.push('/profile/connect-wallet');
    } else if (user && user.kycRequired) {
      history.push('/profile/kyc');
    } else {
      history.push('/token/add');
    }
  };

  const goToMyProfile = () => {
    history.push(`/profile`);
    handleMenuClose();
  };

  const isMenuOpen = Boolean(anchorEl);
  const isNotificationsOpen = Boolean(anchorNotificationsEl);

  const renderMenuItems = useMemo(() => {
    return [
      <MenuItem
        key={uuidv4()}
        disabled
        sx={{
          '&.MuiMenuItem-root.Mui-disabled': {
            opacity: '1',
          },
        }}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap="10px"
          className="user-avatar"
        >
          <Stack>
            {user?.avatar ? (
              <img
                src={`${
                  user?.avatar.includes('mypinata')
                    ? `${user?.avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                    : user?.avatar
                }`}
                width="30"
                height="30"
                className={cx(styles.avatarBig)}
              />
            ) : (
              <Identicon
                account={user.userId}
                size={36}
                className={cx(styles.avatarBig)}
              />
            )}
          </Stack>
          <Stack>
            <div>{user.name}</div>
            <div>{user.email}</div>
          </Stack>
        </Stack>
      </MenuItem>,
      <Divider key={uuidv4()} />,
      isMobile ? (
        <div className={styles.mobileAction} key={uuidv4()}>
          {process.env.REACT_APP_ENABLE_WEB3 && (
            <StyledButton
              className={cx(styles.navigation, styles.active)}
              onClick={goToAddToken}
            >
              Add Token
            </StyledButton>
          )}
          <StyledBadge
            badgeContent={'!'}
            invisible={
              ![CREATOR_STATUSES.PAUSED, CREATOR_STATUSES.REJECTED].includes(
                user.creatorStatusId
              )
            }
            bgColor={'red'}
          >
            <StyledButton
              secondary="true"
              className={styles.navigation}
              onClick={goToCreateNft}
            >
              Create
            </StyledButton>
          </StyledBadge>
        </div>
      ) : (
        undefined
      ),
      isMobile ? (
        <MemoizedMobileNotifications
          key={uuidv4()}
          setOpenDrawer={setOpenDrawer}
          onNotificationRead={onNotificationRead}
        />
      ) : (
        undefined
      ),
      account ? (
        <MenuItem key={uuidv4()} onClick={handleConnectWallet}>
          <ListItemIcon>
            <IconWallet />
          </ListItemIcon>
          <ListItemText
            sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            {shortenAddress(account)}
          </ListItemText>
        </MenuItem>
      ) : process.env.REACT_APP_ENABLE_WEB3 ? (
        <MenuItem
          key={uuidv4()}
          onClick={
            user.address
              ? handleConnectWallet
              : () => history.push('/profile/connect-wallet')
          }
        >
          <ListItemIcon>
            <IconWallet />
          </ListItemIcon>
          <ListItemText>
            {user.address ? `Connect Wallet` : `Verify Wallet`}
          </ListItemText>
        </MenuItem>
      ) : (
        <></>
      ),
      isMobile ? (
        <MenuItem key={uuidv4()} onClick={handleExploreNavigate}>
          <ListItemIcon
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
                color: '#141313',
              },
            }}
          >
            <HomeIconOutlined />
          </ListItemIcon>
          <ListItemText>Explore</ListItemText>
        </MenuItem>
      ) : (
        undefined
      ),
      <MenuItem key={uuidv4()} onClick={goToMyProfile}>
        <ListItemIcon>
          <IconPerson />
        </ListItemIcon>
        <ListItemText>View Profile</ListItemText>
      </MenuItem>,
      <Divider key={uuidv4()} />,
      <Accordion key={uuidv4()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListItemIcon>
            <IconSocialMedia />
          </ListItemIcon>
          <ListItemText>Social Media</ListItemText>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            gap: 0.5,
            '& svg': {
              fill: 'lightgray',
              fontSize: '20px',
            },
            '& button': {
              p: 1,
            },
          }}
        >
          <IconButton
            sx={{ ml: '40px !important' }}
            size="small"
            onClick={() => handleOpenPlatform('twitter')}
          >
            <XIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenPlatform('instagram')}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenPlatform('discord')}
          >
            <IconDiscord />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenPlatform('telegram')}
          >
            <TelegramIcon />
          </IconButton>
        </AccordionDetails>
      </Accordion>,
      <Accordion key={uuidv4()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListItemIcon>
            <IconSupport />
          </ListItemIcon>
          <ListItemText>Support</ListItemText>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& svg': {
              fill: 'lightgray',
              fontSize: '20px',
            },
          }}
        >
          <MenuItem
            sx={{
              cursor: 'default',
              color: 'var(--primary-background-color) !important',
              '&:hover': {
                backgroundColor: 'var(--primary-background-color)',
              },
            }}
          >
            <ListItemText>FAQs</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>Contact Us</ListItemText>
          </MenuItem>
        </AccordionDetails>
      </Accordion>,
      <Divider key={uuidv4()} sx={{ mb: 1 }} />,
      <Accordion key={uuidv4()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListItemIcon>
            <IconPaper />
          </ListItemIcon>
          <ListItemText>Legal</ListItemText>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& svg': {
              fill: 'lightgray',
              fontSize: '20px',
            },
          }}
        >
          <MenuItem onClick={() => history.push('/policies')}>
            <ListItemText>Policies</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => history.push('/licenses')}>
            <ListItemText>Licenses</ListItemText>
          </MenuItem>
        </AccordionDetails>
      </Accordion>,
      <Divider key={uuidv4()} />,
      <MenuItem
        key={uuidv4()}
        onClick={handleSignOut}
        sx={{
          mb: '10px !important',
        }}
      >
        <ListItemIcon>
          <IconLogout />
        </ListItemIcon>
        <ListItemText>Log out</ListItemText>
      </MenuItem>,
      <div key={uuidv4()} className={styles.modeContainer}>
        <IconButton
          onClick={() =>
            dispatch(
              HeaderOptions.toggleMode(mode === 'light' ? 'dark' : 'light')
            )
          }
        >
          {mode === 'light' ? (
            <DarkMode className={styles.active} />
          ) : (
            <LightMode className={styles.active} />
          )}
        </IconButton>
      </div>,
    ].filter(Boolean);
  }, [isMobile, user?.name, user?.avatar, mode]);

  const renderMenu = useMemo(
    () => (
      <Menu
        id="menu-options"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        onMouseLeave={handleMenuClose}
        disableScrollLock
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        className={styles.mainMenu}
        disableAutoFocusItem
        sx={{
          zIndex: 11000,
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            pointerEvents: 'auto',
            mt: 1,
            backgroundColor: 'var(--place-holder-color)',
            borderRadius: 'var(--border-radius)',
            color: 'var(--primary-text-color)',
          },
          '& .MuiMenu-list': {
            minWidth: '250px !important',
            maxWidth: '250px !important',
            backgroundColor: 'var(--place-holder-color)',
            '& .MuiListItemIcon-root': {
              minWidth: 30,
              '& svg': {
                transform: 'scale(1.1)',
                stroke: 'var(--primary-text-color)',
              },
            },
            '& .MuiDivider-root': {
              borderColor: 'var(--border-color) !important',
            },
          },
          '& .MuiMenuItem-root': {
            display: 'flex',
            justifyContent: 'flex-start',
            pl: 2,
            pt: 0.75,
            pb: 0.75,
          },
          '& .MuiAccordion-root': {
            margin: '0 !important',
            position: 'unset',
            borderRadius: 0,
            boxShadow: 'none',
            '& .MuiListItemIcon-root': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiAccordionSummary-content': {
              m: 0,
            },
            '& .MuiAccordionSummary-root': {
              minHeight: '40px',
              '.MuiAccordionSummary-expandIconWrapper': {
                '& svg': {
                  fill: 'var(--primary-text-color)',
                },
              },
            },
            '& .MuiAccordionDetails-root': {
              display: 'flex',
              p: 0,
              '& .MuiMenuItem-root': {
                pt: '2px',
                pb: '2px',
              },
            },
            '& .MuiAccordion-region .MuiAccordionDetails-root': {
              paddingLeft: '0 !important',
              '& li': {
                display: 'flex',
                width: '100%',
                pl: 6,
                '& span': {
                  fontSize: '14px',
                  color: '#b9b9b9',
                },
              },
            },
          },
        }}
      >
        {renderMenuItems}
      </Menu>
    ),
    [isMobile, isMenuOpen, mode]
  );

  const renderNotifications = useMemo(
    () => (
      <Menu
        anchorEl={anchorNotificationsEl}
        open={isNotificationsOpen}
        onClose={handleMenuClose}
        disableScrollLock
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        className={styles.mainMenu}
        disableAutoFocusItem
        sx={{
          zIndex: 11000,
          '& .MuiList-root': {
            p: 2,
            pb: 0,
          },
          '& .MuiMenu-list': {
            minWidth: '350px !important',
            maxWidth: '350px !important',
            maxHeight: '350px',
            overflowY: 'auto',
            '& .MuiListItemIcon-root': {
              minWidth: 30,
              '& svg': {
                transform: 'scale(1.1)',
              },
            },
          },
          '& .MuiMenuItem-root': {
            fontWeight: 600,
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'flex-start',
            pl: 2,
            pt: 0.75,
            pb: 0.75,
            '& span': {
              fontSize: '14px',
            },
          },

          '& .MuiAccordion-root': {
            margin: '0 !important',
            position: 'unset',
            borderRadius: 0,
            boxShadow: 'none',
            '& .MuiListItemIcon-root': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiAccordionSummary-content': {
              m: 0,
            },
            '& .MuiAccordionSummary-root': {
              minHeight: '40px',
            },
            '& .MuiAccordionDetails-root': {
              display: 'flex',
              p: 0,
              '& .MuiMenuItem-root': {
                pt: '2px',
                pb: '2px',
              },
            },
            '& .MuiAccordion-region .MuiAccordionDetails-root': {
              paddingLeft: '0 !important',
              '& li': {
                display: 'flex',
                width: '100%',
                pl: 6,
                '& span': {
                  fontSize: '12px',
                  color: '#b9b9b9',
                },
              },
            },
          },
        }}
      >
        <MemoizedNotificationItems
          setOpenDrawer={setOpenDrawer}
          onNotificationRead={onNotificationRead}
        />
      </Menu>
    ),
    [isMobile, isNotificationsOpen, totalNotifications]
  );

  const exploreAndLogin = useMemo(
    () => (
      <div className={styles.buttons}>
        {process.env.REACT_APP_ENABLE_WEB3 && (
          <StyledButton
            className={cx(styles.navigation, styles.active)}
            onClick={goToAddToken}
          >
            Add Token
          </StyledButton>
        )}

        <StyledButton
          secondary="true"
          className={styles.explore}
          onClick={handleSignIn}
        >
          Create
        </StyledButton>
        <StyledButton
          className={styles.navigation}
          onClick={handleExploreNavigate}
        >
          Explore
        </StyledButton>
        <StyledButton className={styles.navigation} onClick={handleSignIn}>
          Log in
        </StyledButton>
      </div>
    ),
    [mode]
  );

  const handleDrawerClose = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const handleMenuButtonClick = useCallback(() => {
    setOpenDrawer(prev => !prev);
  }, []);

  const renderMobileMenu = useMemo(
    () => (
      <>
        <Drawer
          anchor="top"
          open={openDrawer}
          onClose={handleDrawerClose}
          sx={{
            '.MuiPaper-root': {
              backgroundColor: 'var(--background-color)',
              color: 'var(--primary-text-color)',
              width: '100%',
              position: 'static',
            },
            '.MuiMenuItem-root': {
              display: 'flex',
              justifyContent: 'flex-start',
              pl: 2,
              pt: 0.75,
              pb: 0.75,
              minHeight: 'auto',
            },
            '.MuiListItemIcon-root': {
              minWidth: '30px !important',

              '& svg': {
                transform: 'scale(1.1)',
                stroke: 'var(--primary-text-color)',
              },
            },
            '& .MuiAccordion-root': {
              margin: '0 !important',
              position: 'unset',
              borderRadius: 0,
              boxShadow: 'none',
              '& .MuiAccordionSummary-expandIconWrapper': {
                color: 'var(--primary-text-color)',
              },
              '& .MuiListItemIcon-root': {
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiAccordionSummary-content': {
                m: 0,
              },
              '& .MuiAccordionSummary-root': {
                minHeight: '40px',
              },
              '& .MuiAccordionDetails-root': {
                display: 'flex',
                p: 0,
                '& .MuiMenuItem-root': {
                  pt: '2px',
                  pb: '2px',
                },
              },
              '& .MuiAccordion-region .MuiAccordionDetails-root': {
                paddingLeft: '0 !important',
                '& li': {
                  display: 'flex',
                  width: '100%',
                  pl: 6,
                  '& span': {
                    fontSize: '14px',
                    color: '#b9b9b9',
                  },
                },
              },
            },
          }}
        >
          <div className={styles.drawer}>
            <div className={styles.drawerMenuItems}>
              {!authToken ? exploreAndLogin : renderMenuItems}
            </div>
          </div>
        </Drawer>
        {!hideMenu && (
          <div
            className={cx(styles.menuButton, openDrawer && styles.close)}
            onClick={handleMenuButtonClick}
          >
            <div>&nbsp;</div>
          </div>
        )}
      </>
    ),
    [
      isMobile,
      openDrawer,
      hideMenu,
      authToken,
      renderMenuItems,
      handleDrawerClose,
      handleMenuButtonClick,
      mode,
    ]
  );
  console.log('user.creatorStatusId', user.creatorStatusId);
  const renderLinks = useMemo(
    () =>
      hideMenu ? (
        <></>
      ) : (
        <>
          {!authToken ? (
            exploreAndLogin
          ) : (
            <Box
              className={cx(styles.account)}
              sx={{
                '& .MuiIconButton-root': {
                  p: 1,
                  width: '40px',
                  height: '40px',
                },
              }}
            >
              {!user?.isAdmin && (
                <>
                  {process.env.REACT_APP_ENABLE_WEB3 && (
                    <StyledButton
                      className={cx(
                        // styles.disabled,
                        styles.navigation,
                        styles.active
                      )}
                      onClick={goToAddToken}
                      // disabled={true}
                    >
                      Add Token
                    </StyledButton>
                  )}

                  <StyledBadge
                    badgeContent={'!'}
                    invisible={
                      ![
                        CREATOR_STATUSES.PAUSED,
                        CREATOR_STATUSES.REJECTED,
                      ].includes(user.creatorStatusId)
                    }
                    bgColor={'red'}
                  >
                    <StyledButton
                      secondary="true"
                      className={cx(styles.navigation, styles.create)}
                      onClick={goToCreateNft}
                    >
                      Create
                    </StyledButton>
                  </StyledBadge>
                </>
              )}
              <IconButton onClick={handleExploreNavigate}>
                <HomeIcon />
              </IconButton>
              <IconButton onClick={handleNotificationsMenuOpen}>
                <StyledBadge
                  badgeContent={notificationBadgeContent(totalNotifications)}
                  invisible={!totalNotifications}
                >
                  <IconBell />
                </StyledBadge>
              </IconButton>
              <IconButton
                className={styles.avatar}
                onClick={handleProvileNavigate}
                onMouseOver={e => {
                  setAnchorEl(e.currentTarget);
                }}
                aria-controls={isMenuOpen ? 'menu-options' : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? 'true' : undefined}
              >
                {user?.avatar ? (
                  <img
                    src={`${
                      user?.avatar.includes('mypinata')
                        ? `${user?.avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                        : user?.avatar
                    }`}
                    width="24"
                    height="24"
                    className={styles.avatarSmall}
                  />
                ) : (
                  <Identicon
                    account={user.userId}
                    size={24}
                    className={styles.avatarSmall}
                  />
                )}
              </IconButton>
            </Box>
          )}
          {renderMenu}
          {renderNotifications}
        </>
      ),
    [
      isMobile,
      authToken,
      isMenuOpen,
      isNotificationsOpen,
      user?.avatar,
      hideMenu,
      totalNotifications,
      mode,
    ]
  );
  return (
    <>
      <AppBar
        elevation={0}
        className={cx(
          styles.mainHeader,
          hideMenu && styles.transparent,
          openDrawer && styles.removeBorder,
          isSticky && styles.fixed,
          isSticky === 'hidden' && styles.appBarHidden,
          isSticky === 'sticky' && styles.appBarSlide
        )}
        onMouseLeave={() => setAnchorEl(null)}
      >
        <CssBaseline />
        <div className={cx(styles.onlyCover, openDrawer && styles.shorter)}>
          &nbsp;
        </div>
        <Toolbar
          className={cx(styles.header, openDrawer && styles.shorter)}
          sx={{ minHeight: '20px' }}
        >
          <div className={styles.left}>
            <Link to="/" className={cx(styles.logo, hideMenu && styles.upSize)}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className={styles.middle}>
            <div className={cx(styles.searchBar, openDrawer && styles.hidden)}>
              <SearchBox
                className={cx('search-box-header')}
                isSticky={isSticky}
                mode={mode}
              />
            </div>
          </div>
          <div className={styles.right}>
            {renderMobileMenu}
            {renderLinks}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withRouter(Header);
