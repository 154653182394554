import React, { useState } from 'react';
import styles from './../styles.module.scss';
import LicenseGrid from '../ExternalDocumentsGrid';

import Header from 'components/header';
import FooterAction from '../../../components/FooterAction';
import Footer from '../../../components/Footer';
import { StyledAccordion } from 'components/StyledComponents';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import {
  businessPolicies,
  paymentPolicies,
  riskPolicies,
} from 'constants/policy.constants';

const Policies = () => {
  const [firstExpanded, setFirstExpanded] = useState(false);
  const [secondExpanded, setSecondExpanded] = useState(false);
  const [thirdExpanded, setThirdExpanded] = useState(false);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated="February 28, 2025"
          title="Policies and Legal"
          description=""
          url=""
        >
          <div className={styles.content}>
            <StyledAccordion
              border="1px var(--border-color) solid !important"
              sx={{ overflow: 'hidden', width: '100%' }}
              expanded={firstExpanded}
              onChange={() => setFirstExpanded(prev => !prev)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                className={styles.inputGroup}
                id="panel1-header"
              >
                Business & Compliance
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {businessPolicies.map(item => (
                    <li key={item.id}>
                      <Link to={item.url}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion
              border="1px var(--border-color) solid !important"
              sx={{ overflow: 'hidden', width: '100%' }}
              expanded={secondExpanded}
              onChange={() => setSecondExpanded(prev => !prev)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                className={styles.inputGroup}
                id="panel1-header"
              >
                Risk & Compliance Measures
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {riskPolicies.map(item => (
                    <li key={item.id}>
                      <Link to={item.url}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion
              border="1px var(--border-color) solid !important"
              sx={{ overflow: 'hidden', width: '100%' }}
              expanded={thirdExpanded}
              onChange={() => setThirdExpanded(prev => !prev)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                className={styles.inputGroup}
                id="panel1-header"
              >
                Payment & Technical
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {paymentPolicies.map(item => (
                    <li key={item.id}>
                      <Link to={item.url}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </StyledAccordion>
          </div>
        </LicenseGrid>
      </div>
      <Footer />
      <FooterAction />
    </div>
  );
};
export default Policies;
