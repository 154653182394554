import { io } from 'socket.io-client';

let socket = null;
let authToken = null;
let wsUrl = null;
const broadcast = new BroadcastChannel('ws-messages');

function connectWebSocket() {
  if (!wsUrl || !authToken) {
    console.error('Missing WebSocket URL or Auth Token');
    return;
  }

  //#region Establish the WebSocket connection
  socket = io(wsUrl, {
    query: { authToken },
    transports: ['websocket'],
  });

  socket.on('connect', () => {
    broadcast.postMessage({ type: 'status', message: 'connected' });
  });

  socket.on('disconnect', () => {
    broadcast.postMessage({ type: 'status', message: 'disconnected' });

    setTimeout(connectWebSocket, 5000);
  });

  socket.on('error', error => {
    broadcast.postMessage({ type: 'error', message: error.message });
  });
  //#endregion

  socket.on('notification', notification => {
    broadcast.postMessage({ type: 'notification', data: notification });
  });

  socket.on('asset-checked', obj => {
    broadcast.postMessage({ type: 'asset-checked', data: obj });
  });

  socket.on('onboarding-success', obj => {
    broadcast.postMessage({
      type: 'websocket-event',
      data: obj,
      eventType: 'onboardingSuccess',
    });
  });

  socket.on('asset-sold-by-paypal', obj => {
    broadcast.postMessage({
      type: 'websocket-event',
      data: obj,
      eventType: 'assetSoldByPaypal',
    });
  });

  socket.on('offer-accepted', obj => {
    broadcast.postMessage({
      type: 'websocket-event',
      data: obj,
      eventType: 'offerAccepted',
    });
  });

  socket.on('offer-created', obj => {
    broadcast.postMessage({
      type: 'websocket-event',
      data: obj,
      eventType: 'offerCreated',
    });
  });

  socket.on('offer-canceled', obj => {
    broadcast.postMessage({
      type: 'websocket-event',
      data: obj,
      eventType: 'offerCanceled',
    });
  });
}

broadcast.addEventListener('message', event => {
  const { type, wsUrl: newWsUrl, authToken: newAuthToken } = event.data;

  if (type === 'start') {
    authToken = newAuthToken;
    wsUrl = newWsUrl;
    connectWebSocket();
  } else if (type === 'stop' && socket) {
    socket.disconnect();
    socket = null;
  }
});
