import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { StyledButton } from 'components/StyledComponents';
import HeaderActions from 'actions/header.actions';
import FilterActions from 'actions/filter.actions';
import Header from 'components/header';
import FooterAction from 'components/FooterAction';
import Footer from 'components/Footer';

import MasonaryNFTs from 'components/MasonaryNFTs';
import SortByButton from 'components/SortByButton';
import SearchBox from 'components/SearchBox';

import Main from './main';

import styles from './styles.module.scss';
import { Divider } from '@mui/material';
import MetaTags from 'components/MetaTags';

const LandingPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Auth);
  const { mode } = useSelector(state => state.HeaderOptions);

  const isLoggedIn = useMemo(() => Object.keys(user).length > 0, [user]);
  const [isSticky, setIsSticky] = useState(null);

  const [nftTab, setNftTab] = useState('createdAt');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
    dispatch(FilterActions.resetStatusFilter());

    if (!isLoggedIn) {
      // handle stciky behavior
      const handleScroll = () => {
        const search = document.getElementById('search-box-main-page');
        if (search) {
          const distanceToTop = search.getBoundingClientRect()?.top || 0;

          // distance from the top
          if (window.scrollY < 100) {
            setIsSticky('null');
          } else if (distanceToTop > 60) {
            setIsSticky('hidden');
          } else {
            setIsSticky('sticky');
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    } else {
      setIsSticky('sticky');
    }
  }, [isLoggedIn]);

  return (
    <div className={styles.container}>
      <MetaTags
        title="Minti"
        description="Buy and Sell Professional Art and Media"
      />
      <Header isSticky={isSticky} />
      {!isLoggedIn && <Main />}

      {!isLoggedIn && (
        <div className={styles.searchSection}>
          <SearchBox
            id="search-box-main-page"
            isSticky={isSticky}
            className={isSticky === 'sticky' ? styles.hidden : undefined}
          />
        </div>
      )}

      <div
        className={cx(styles.tabGroup, isLoggedIn && styles.loggedInTabGroup)}
      >
        <StyledButton
          className={cx(nftTab === 'createdAt' && styles.active)}
          onClick={() => {
            setNftTab('createdAt');
          }}
        >
          Recent
        </StyledButton>
        {/* <StyledButton
          className={cx(nftTab === 'trending' && styles.active)}
          onClick={() => {
            setNftTab('trending');
          }}
        >
          Trending
        </StyledButton> */}
        <StyledButton
          className={cx(nftTab === 'mostPopular' && styles.active)}
          onClick={() => {
            setNftTab('mostPopular');
          }}
        >
          Most Popular
        </StyledButton>
        {isLoggedIn && (
          <StyledButton
            secondary="true"
            className={cx(nftTab === 'following' && styles.active)}
            onClick={() => {
              setNftTab('following');
            }}
          >
            Following
          </StyledButton>
        )}

        <SortByButton
          className={styles.sortByButton}
          sortBy={nftTab}
          setSortBy={tab => setNftTab(tab)}
          mode={mode}
        />
      </div>

      <Divider className={styles.divider} />

      <MasonaryNFTs
        isLandingPage
        sortBy={nftTab}
        setSortBy={tab => setNftTab(tab)}
        setContainerLoading={loading ? setLoading : null}
      />
      <Footer />
      <FooterAction showFooter />
    </div>
  );
};

export default LandingPage;
