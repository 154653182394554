import React, { useCallback, useState } from 'react';
import styles from '../styles.module.scss';
import { Grid } from '@mui/material';
import BackgroundIcon from 'assets/imgs/bg.png';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { StyledButton } from 'components/StyledComponents';
import { pdfjs, Document, Page } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '../document.css';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 800;

const LicenseGrid = ({ children, lastUpdated, title, description, url }) => {
  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${title}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const [numPages, setNumPages] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }
  const onResize = useCallback(entries => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);
  useResizeObserver(containerRef, resizeObserverOptions, onResize);
  return (
    <>
      <Grid container className={styles.inner} direction="column">
        <div className={styles.header}>
          <div className={styles.background}>
            <div className={styles.bg1}>
              <img src={BackgroundIcon} alt="backgroud" />
            </div>
            <div className={styles.bg2}>
              <img src={BackgroundIcon} alt="background" />
            </div>
          </div>
          <div className={styles.headerContent}>
            <div className={styles.time}>{`Current as of ${lastUpdated}`}</div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        {url && (
          <div className={styles.download}>
            <StyledButton secondary="true" onClick={downloadFile}>
              Download
              <FileDownloadOutlinedIcon />
            </StyledButton>
          </div>
        )}
        <div className={styles.content}>
          {url ? (
            <div className="document" ref={setContainerRef}>
              <Document
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                con
              >
                {Array.from(new Array(numPages), (_el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={
                      containerWidth
                        ? Math.min(containerWidth, maxWidth)
                        : maxWidth
                    }
                  />
                ))}
              </Document>
            </div>
          ) : (
            children
          )}
        </div>
      </Grid>
    </>
  );
};

export default LicenseGrid;
