import React from 'react';
import styles from './styles.module.scss';
import InputUI from '@mui/material/Input';

import cx from 'classnames';
const Input = ({
  hasError,
  label,
  isTextArea = false,
  className,
  classNameCont,
  ...props
}) => {
  return (
    <div className={cx(styles.inputContainer, classNameCont)}>
      {label && <div className={styles.label}>{label}</div>}
      {!isTextArea ? (
        <InputUI
          className={cx(styles.input, hasError && styles.hasError, className)}
          {...props}
          sx={{
            '&.Mui-focused': {
              borderColor: 'var(--primary-color)',
            },
          }}
        />
      ) : (
        <textarea
          className={cx(
            styles.input,
            styles.textarea,
            hasError && styles.hasError
          )}
          {...props}
        />
      )}
    </div>
  );
};

export default Input;
